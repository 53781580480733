import { queryWithMethod } from "./queries";

document.addEventListener("change", async event => {
  const target = event.target as HTMLInputElement;
  const csrfToken = document
    .querySelector('[name="csrf-token"]')
    .getAttribute("content");

  // Assign All-State Entry
  if (target.getAttribute("data-action") === "all-state-select") {
    const event = target.value;
    const ensemble = target.dataset.ensemble;
    const events = await queryWithMethod(
      `/admins/all_state/ensembles?ensemble_id=${ensemble}&event=${event}`,
      csrfToken,
      "post"
    );
  }

  // Assign Elementary All-State Chorus Entry
  if (target.getAttribute("data-action") === "elementary-all-state-select") {
    const event = target.value;
    const ensemble = target.dataset.ensemble;
    const events = await queryWithMethod(
      `/admins/all_state/elementary_all_states?ensemble_id=${ensemble}&event=${event}`,
      csrfToken,
      "post"
    );
  }

  // Assign Composition Placement
  if (target.getAttribute("data-action") === "composition-place-select") {
    const place = target.value;
    const composition = target.dataset.placeComposition;
    const events = await queryWithMethod(
      `/admins/all_state/compositions?composition_id=${composition}&place=${place}`,
      csrfToken,
      "post"
    );
  }

  // Assign Composition Track
  if (target.getAttribute("data-action") === "composition-track-select") {
    const track = target.value;
    const composition = target.dataset.trackComposition;
    const events = await queryWithMethod(
      `/admins/all_state/compositions?composition_id=${composition}&track=${track}`,
      csrfToken,
      "post"
    );
  }

  // Assign Festival Entries
  if (target.getAttribute("data-action") === "festival-select") {
    const event = target.value;
    const audition = target.dataset.audition;
    const events = await queryWithMethod(
      `/admins/festivals/festival_entries?auditionId=${audition}&event=${event}`,
      csrfToken,
      "post"
    );
  }

  // Assign Nine Ten Entries
  if (target.getAttribute("data-action") === "nine-ten-select") {
    const event = target.value;
    const audition = target.dataset.audition;
    const events = await queryWithMethod(
      `/admins/festivals/nine_tens?auditionId=${audition}&event=${event}`,
      csrfToken,
      "post"
    );
  }

  // Assign FMES
  if (target.getAttribute("data-action") === "fmes-select") {
    const event = target.value;
    const fmes = target.dataset.futureEducator;
    const events = await queryWithMethod(
      `/admins/all_state/future_educators?fmes_id=${fmes}&event=${event}`,
      csrfToken,
      "post"
    );
  }

  // Assign Custom Event
  if (target.getAttribute("data-action") === "custom-event-select") {
    const event = target.value;
    const pilotEvent = target.dataset.pilotEvent;
    const events = await queryWithMethod(
      `/admins/events/custom_events/edit_pilot_event?pilot_event_id=${pilotEvent}`,
      csrfToken,
      "post"
    );
  }

  // Assign rubric ranks
  if (target.getAttribute("data-action") === "rank-rubric") {
    console.log("data", target.dataset);
    const ranking = target.value;
    const rubricId = target.dataset.rubricId;
    const events = await queryWithMethod(
      `/judges/rankings?rubric_id=${rubricId}&ranking=${ranking}`,
      csrfToken,
      "post"
    );
  }
});
