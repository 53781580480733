import { queryWithMethod } from "./queries";

// Toggle auditioned for auditions
document.addEventListener("DOMContentLoaded", async () => {
  const auditionedFestival = document.querySelectorAll(
    ".auditioned-festival-js"
  );

  auditionedFestival.forEach(box => {
    let checkbox = box as HTMLInputElement;

    checkbox.addEventListener("change", async event => {
      console.log("hiisd");
      const target = event.target as HTMLInputElement;
      const ensemble = target.dataset.ensemble;
      const csrfToken = document
        .querySelector('[name="csrf-token"]')
        .getAttribute("content");
      console.log(event);
      if (checkbox.checked) {
        const events = await queryWithMethod(
          `/admins/festivals/festival_entries/${ensemble}/auditioned?checked=true`,
          csrfToken,
          "put"
        );
      } else {
        const events = await queryWithMethod(
          `/admins/festivals/festival_entries/${ensemble}/auditioned?checked=false`,
          csrfToken,
          "put"
        );
      }

      location.reload();
    });
  });
});
